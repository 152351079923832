import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Navigation from './navigation'
import { toKebabCase } from '../helpers'

import * as style from '../styles/post.module.css'

const Post = ({
  title,
  date,
  path,
  coverImage,
  author,
  readingTime,
  excerpt,
  tags,
  html,
  previousPost,
  nextPost,
}) => {
  const previousPath = previousPost && previousPost.frontmatter.path
  const previousLabel = previousPost && previousPost.frontmatter.title
  const nextPath = nextPost && nextPost.frontmatter.path
  const nextLabel = nextPost && nextPost.frontmatter.title

  const isExcerpt = !!excerpt

  return (
    <div className={style.post}>
      <div className={style.postContent}>
        <h1 className={style.title}>
          {path ? <Link to={path}>{title}</Link> : title}
        </h1>
        <div className={style.meta}>
          {date} {readingTime && <>&bull; ~{readingTime}</>} {author && <>&bull; by {author}</>}
          {tags ? (
            <div className={style.tags}>
              {tags.map(tag => (
                <Link to={`/tag/${toKebabCase(tag)}`} key={toKebabCase(tag)}>
                  <span className={style.tag}>#{tag}</span>
                </Link>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      {coverImage && (
        isExcerpt ? (
          <Link to={path}>
            <GatsbyImage
              image={coverImage.childImageSharp.gatsbyImageData}
              className={style.coverImage} />
          </Link>
        ) : (
          <GatsbyImage
            image={coverImage.childImageSharp.gatsbyImageData}
            className={style.coverImage}
            alt={title} />
        )
      )}
      {isExcerpt ? (
        <>
          <p>{excerpt}</p>
          <Link to={path} className={style.readMore}>
            Read more &rarr;
          </Link>
        </>
      ) : (
        <>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <Navigation
            previousPath={previousPath}
            previousLabel={previousLabel}
            nextPath={nextPath}
            nextLabel={nextLabel}
          />
        </>
      )}
    </div>
  )
}

Post.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  author: PropTypes.string,
  readingTime: PropTypes.string,
  excerpt: PropTypes.string,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  previousPost: PropTypes.object,
  nextPost: PropTypes.object,
}

export default Post
